import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import ReactDiffViewer from 'react-diff-viewer';

export interface DiffCheckerViewerProps {
  viewMode: string;
  source: string;
  target: string;
}

const DiffCheckerViewer = ({ viewMode, source, target }: DiffCheckerViewerProps) => {
  return (
    <div style={{ wordBreak: 'break-all' }}>
      <ReactDiffViewer
        oldValue={source}
        newValue={target}
        showDiffOnly={false}
        hideLineNumbers={true}
        splitView={viewMode === 'split'}
      />
    </div>
  );
}

export default DiffCheckerViewer;
