import React, { useState } from 'react';
import { Form, Input, Row, Col, Button } from 'antd';

const { TextArea } = Input;

const onFinish = (values: { source: string, target: string }) => {
  return values;
};

export interface DiffCheckerFormProps {
  defaultSource?: string;
  defaultTarget?: string;
  onFinish?: ((values: {
    source: string;
    target: string;
  }) => void);
}

const DiffCheckerForm = ({ defaultSource, defaultTarget, onFinish }: DiffCheckerFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Form
      name="diffForm"
      onFinish={onFinish ? (values) => {
        setLoading(true);
        onFinish(values);
      } : undefined}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="source"
            initialValue={defaultSource}
            rules={[{ required: true, message: 'This is a required field.' }]}
            noStyle
          >
            <TextArea
              className="diff-text-area"
              placeholder="Enter the original text"
              required
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="target"
            initialValue={defaultTarget}
            rules={[{ required: true, message: 'This is a required field.' }]}
            noStyle
          >
            <TextArea
              className="diff-text-area"
              placeholder="Enter the changed text"
              required
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item noStyle>
            <Button
              style={{ float: 'right' }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Compare
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DiffCheckerForm;
