import React, { useState } from 'react';
import { PageHeader, Radio, Row, Col, Skeleton } from 'antd';
import DiffCheckerViewer from './DiffCheckerViewer';

export interface DiffCheckerViewerPageProps {
  source: string;
  target: string;
  onBack?: () => any;
};

const DiffCheckerViewerPage = ({ source, target, onBack }: DiffCheckerViewerPageProps) => {
  const [viewMode, setViewMode] = useState<string>('split');
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <Row>
        <Col sm={14}>
          <PageHeader
            title="diff checker"
            subTitle="compare"
            onBack={onBack}
          />
        </Col>

        <Col sm={10}>
          <Radio.Group
            id="view-mode-radio-group"
            buttonStyle="solid"
            value={viewMode}
            onChange={(evt) => {
              setViewMode(evt?.target?.value || 'split');
              if ((source.length + target.length) > 5000) {
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, Math.min((source.length + target.length) / 10, 2000));
              }
            }}
          >
            <Radio.Button value="unified">Unified</Radio.Button>
            <Radio.Button value="split">Split</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Skeleton loading={loading} paragraph={{ rows: 15 }} active>
        <DiffCheckerViewer
          viewMode={viewMode}
          source={source}
          target={target}
        />
      </Skeleton>
    </>
  )
};

export default DiffCheckerViewerPage;
