import React from 'react';
import { Layout, Breadcrumb } from 'antd';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import ReactDiffViewer from 'react-diff-viewer';
import { DiffCheckerPage } from './components';

const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    <div className="App">
      <Layout>
        <Header className="site-header">
          <div className="logo">
            <span id="logo-diff">diff</span>
            <span id="logo-dot">.</span>
            <span id="logo-fyi">fyi</span>
          </div>
        </Header>

        <Content className="app-content" style={{ padding: '0 24px' }}>
          <BrowserRouter>
            <DiffCheckerPage />
          </BrowserRouter>
        </Content>

        <Footer style={{ textAlign: 'center' }}>
          diff.fyi ©2022
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
