import React from 'react';
import { DiffCheckerForm } from '.';
import { PageHeader } from 'antd';

export interface DiffCheckerHomePageProps {
  source?: string;
  target?: string;
  onFormComplete?: ((values: {
    source: string;
    target: string;
  }) => void);
}

const DiffCheckerHomePage = ({ source, target, onFormComplete }: DiffCheckerHomePageProps) => (
  <>
    <PageHeader title="diff checker" />
    <DiffCheckerForm
      defaultSource={source}
      defaultTarget={target}
      onFinish={onFormComplete}
    />
  </>
)

export default DiffCheckerHomePage;
