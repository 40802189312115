import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import DiffCheckerHomePage from './DiffCheckerHomePage';
import DiffCheckerViewerPage from './DiffCheckerViewerPage';

const LOCAL_STORAGE_PREFIX = 'diff_checker_';
const LOCAL_STORAGE_SOURCE = LOCAL_STORAGE_PREFIX + 'source';
const LOCAL_STORAGE_TARGET = LOCAL_STORAGE_PREFIX + 'target';

const getSourceAndTargetFromLocalStorage = () => {
  const source = localStorage.getItem(LOCAL_STORAGE_SOURCE);
  const target = localStorage.getItem(LOCAL_STORAGE_TARGET);
  return { source, target };
};

const setSourceAndTargetInLocalStoage = (source: string, target: string) => {
  localStorage.setItem(LOCAL_STORAGE_SOURCE, source);
  localStorage.setItem(LOCAL_STORAGE_TARGET, target);
}

const DiffCheckerPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [source, setSource] = useState<string | undefined>();
  const [target, setTarget] = useState<string | undefined>();

  const setSourceAndTarget = (s: string, t: string) => {
    setSource(s);
    setTarget(t);
  }

  useEffect(() => {
    if (location.pathname === '/compare') {
      const { source: s, target: t } = getSourceAndTargetFromLocalStorage();
      if (s && t) {
        setSourceAndTarget(s, t);
        navigate('/compare');
      }
    }
  }, []);

  return (
    <Routes>
      <Route path="" element={(
        <DiffCheckerHomePage
          source={source}
          target={target}
          onFormComplete={({ source, target }) => {
            setSourceAndTarget(source, target);
            setSourceAndTargetInLocalStoage(source, target);

            setTimeout(() => {
              navigate("/compare");
            }, Math.min((source.length + target.length) / 10, 2000));
          }}
        />
      )}>
      </Route>
      {(source && target) && (
        <Route
          path="/compare"
          element={(
            <DiffCheckerViewerPage
              source={source}
              target={target}
              onBack={() => navigate('/')}
            />
          )}
        />
      )}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
};

export default DiffCheckerPage;
